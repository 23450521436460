@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* COLORS */
/* RESET */
* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    font-family: "Poppins", Arial, sans-serif;
    /*color: #333;*/
}

code,
kbd,
pre,
samp {
    font-family: "Poppins", Arial, sans-serif !important;
}

*:before,
*:after {
    box-sizing: inherit;
}

a,
button {
    transition: 0.2s;
}

a {
    text-decoration: none;
}

p {
    line-height: 1.5;
}

body {
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
}

.form-holder { border: 1px solid #aaa; border-radius: 15px; padding: 20px; }
.form-holder input[type=text] { width: 100%; }
.form-holder .btn { margin-left:10px; }

.editable { padding: 0px 10px; border: 1px solid transparent; border-radius: 15px; cursor: text; }
.editable:hover { border: 1px solid #bbb; background-color: white; }
.editing { border: 2px solid #bbb !important; }


/* VARIBLES */
/* MODULES */
.eheader { display: flex; flex-wrap: wrap; width: 100%; margin-bottom: 40px; }

.eheader .econtainer {
    width: 100%;
}

.eheader .econtainer a {
    margin-left:15px;
}

.eheader .econtainer .header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 90px;
}

.eheader .econtainer .header .logo {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}

.eheader .econtainer .header .logo img {
    width: 65px;
}

.eheader .econtainer .header .logo p { color: #3c3f66; font-size: 20px; font-weight: bold; line-height: 28px; margin-top: 40px; }

@media (max-width: 510px) {
    .eheader .econtainer .header .logo p { display:none; }
    a.btn span { display: none; }
}

a.btn:hover span { display: contents; }

.eheader .econtainer .header .collection {
    color: #9294be;
    font-size: 18px;
    font-weight: bold;
}

.eheader .banner {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: #f6f6fa;
    padding: 45px 0;
}

.eheader .banner img {
    width: 110px;
    height: 110px;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
    margin-right: 15px;
}

.eheader .banner span {
    font-size: 36px;
    line-height: 50px;
    font-weight: bold;
    color: #3c3f66;
    margin-right: 10px;
}

.eheader .banner samp {
    font-size: 36px;
    line-height: 50px;
    font-weight: bold;
    color: #9294be;
}

.econtainer {
    max-width: 1300px;
    position: relative;
    margin: 0px auto;
}

@media only screen and (max-width: 1300px) {
    .econtainer {
        max-width: 100%;
    }

    .eheader .econtainer {
        padding: 0 16px;
    }

    .eheader .econtainer .header {
        height: 70px;
    }

    .eheader .banner {
        padding: 25px 0;
    }

    .eheader .banner img {
        width: 70px;
        height: 70px;
    }

    .eheader .banner span {
        font-size: 24px;
    }

    .eheader .banner samp {
        font-size: 24px;
    }
}

.eproduct {
    width: 100%;
    max-width: 1332px;
    margin: 0 auto;
}

.eproduct .item {
    float: left;
    width: 333px;
    padding: 0 16px 32px 16px;
    align-content: flex-start;
}

.eproduct .item .image {
    min-height: 190px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 20px;
    align-items: flex-end;
    overflow: hidden;
}

@media only screen and (max-width: 999px) {
    .eproduct .item .image { min-height: 67px; }
}

.eproduct .item .image a { position: absolute; left: 30px; top: 15px; cursor:pointer; opacity:0.5;}
.eproduct .item .image a span { display: none; }
.eproduct .item .image a:hover { opacity: 1; }
.eproduct .item .image a:hover span { display: contents; }
.eproduct .item .image a:nth-of-type(2) { top: 60px; }

.eproduct .item .subtitle {
    width: 100%;
    color: #9294be;
    font-size: 12px;
    margin: 8px 0 2px;
}

.eproduct .item .title {
    width: 100%;
    color: #5d609c;
    font-size: 15px;
    margin-bottom: 4px;
}

.eproduct .item .price {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    width: 100%;
}

.eproduct .item .price span {
    color: #3c3f66;
    font-size: 19px;
    line-height: 1;
    font-weight: bold;
    margin-right: 5px;
}

.eproduct .item .price samp {
    color: #9294be;
    font-size: 14px;
    line-height: 1;
    text-decoration: line-through;
}

@media only screen and (max-width: 1332px) {
    .eproduct {
        max-width: 999px;
    }
}

@media only screen and (max-width: 999px) {
    .eproduct {
        max-width: 666px;
    }
}

@media only screen and (max-width: 666px) {
    .eproduct { max-width: 100%; }

    .eproduct .item { width: 50%; }
    .eproduct .item.video { width: 100%; }
}