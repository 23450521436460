.image>img {
    width: 100%;
    border-radius: 10px;
}

.image {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.eheader .logo>p {
    margin-top: 0 !important;
}

.eproduct .video {
    width: 666px;
    display: block;
}

.eproduct .video .video-player iframe {
    width: 100%;
}

.eheader a {
    color: #9294be;
}

.eheader .econtainer .header .logo img {
    width: 200px;
}

@media only screen and (max-width: 768px) {    
    .eheader {
        margin-bottom: 20px;
    }

    .video-player > iframe {
        height: 320px;
    }
}

@media only screen and (max-width: 576px) {    

    .item.video {
        padding: 0;
        margin-bottom: 20px;
    }

    .video-player > iframe {
        height: 220px;
    }
}

@media only screen and (max-width: 991.98px) {    
    .eheader .econtainer .header .logo img {
        max-height: 73px;
        width: 80%;
    }
}

.flex-container {
    display: flex;
    flex-flow: row wrap;
    /* justify-content: space-around; */
    padding: 0;
    margin: 0 auto;
    list-style: none;
    align-items: baseline;
  }