.img-center {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.notfound {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh !important;
    padding-bottom: 100px;
}

h1 {
    font-size: 6em;
}

.text-center {
    text-align: center;
}

.text-danger {
    color:#dc3545;
}